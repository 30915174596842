import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { getTopRooms } from "../src/services/rooms";
import Layouts from "../src/layouts/Layouts";
import { sponsorsSlider } from "../src/props/sliderProps";
import RoomsSuites from "../src/components/rooms-suites.component";
import TestimonialsFeedbacks from "../src/components/testimonials-feedbacks.component";
import { getTopFeedbacks } from "../src/services/feedbacks";
import BannerSliderHomepage from "../src/components/banner-slider-homepage.component";
import CheckAvailability from "../src/components/check-availability.component";
import Image from "next/image";

const Index = ({ rooms, feedbacks }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Layouts noBg extraCls={"ex"} headernumber>
      {/* Bnner Section */}
      <section className="banner-section">
        <div className="shape">
          <Image
            src="/assets/images/shape/shape-3.png"
            alt=""
            width={858}
            height={845}
          />
        </div>
        <div className="big-title">Booking</div>
        <BannerSliderHomepage />
        <div className="banner-slider-nav">
          <div className="banner-slider-control banner-slider-button-prev">
            <span>
              <i className="fas fa-arrow-right" />
            </span>
          </div>
          <div className="banner-slider-control banner-slider-button-next">
            <span>
              <i className="fas fa-arrow-right" />
            </span>{" "}
          </div>
        </div>
      </section>
      {/* End Bnner Section */}
      {/* Check availability */}
      <CheckAvailability />

      {/* section one */}
      <section className="section-one">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="block-one">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="image mb-30">
                      <Image
                        width={270}
                        height={370}
                        src="/assets/images/resource/image-1.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="inner-box mb-30">
                      <div className="image-two">
                        <Image
                          width={270}
                          height={530}
                          src="/assets/images/resource/image-2.jpg"
                          alt=""
                        />
                      </div>
                      <div className="image-three">
                        <Image
                          src="/assets/images/logo.svg"
                          width={100}
                          height={100}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block-two text-center">
                <div className="image mb-10">
                  <Image
                    width={41}
                    height={40}
                    src="/assets/images/icons/image-2.png"
                    alt=""
                  />
                </div>
                <h2 className="sec-title">
                  Welcome To <br />{" "}
                  <span className="theme-color">
                    Eagles Pyramids View Hotel
                  </span>{" "}
                </h2>
                <div className="text-two">
                  Located in Cairo, 1.1 km from Great Sphinx, Eagles Pyramids
                  View provides accommodation with a terrace, free private
                  parking and a restaurant.
                </div>
                <div className="bottom-content justify-content-center">
                  <div className="ratings">4.6</div>
                  <div className="text-three">
                    Users <br /> Ratings
                  </div>
                  <Link href="/restaurant">
                    <a className="theme-btn btn-style-one dark">
                      <span>Explore Now</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section two */}
      <section className="section-two">
        <div className="auto-container">
          <div className="sub-title text-center">
            New Offers <span>-25%</span>
          </div>
          <h2 className="sec-title text-center text-light">
            Featured Specials
          </h2>
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image">
                  <Image
                    width={370}
                    height={300}
                    src="/assets/images/resource/image-3.jpg"
                    alt=""
                  />
                </div>
                <div className="inner-box">
                  <div className="title">Upto 40% Off</div>
                  <div className="text">
                    Kick off summer <br />
                    in NYC
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image">
                  <Image
                    width={370}
                    height={500}
                    src="/assets/images/resource/image-4.jpg"
                    alt=""
                  />
                </div>
                <div className="inner-box">
                  <div className="title">For This Week</div>
                  <div className="text">
                    $8 Breakfast package <br /> at central market
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="block-three">
                <div className="image">
                  <Image
                    width={370}
                    height={300}
                    src="/assets/images/resource/image-5.jpg"
                    alt=""
                  />
                </div>
                <div className="inner-box">
                  <div className="title">Free Membership</div>
                  <div className="text">
                    Win a 3-night <br /> getaway!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section three */}
      <section className="section-three">
        <div className="auto-container">
          <div className="sub-title">Sponsors</div>
          <h2 className="sec-title">Happy Sponsors</h2>
          {isClient && (
            <Swiper
              {...sponsorsSlider}
              className="theme_carousel swiper-container"
              id="carousel-sponsor"
            >
              <SwiperSlide id="carousel-id-sponsor-2" className="image">
                <Image
                  width={122}
                  height={70}
                  id="carousel-id-image-sponsor-2"
                  src="/assets/images/resource/image-2.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide id="carousel-id-sponsor-3" className="image">
                <Image
                  width={133}
                  height={70}
                  id="carousel-id-image-sponsor-3"
                  src="/assets/images/resource/image-3.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide id="carousel-id-sponsor-4" className="image">
                <Image
                  width={138}
                  height={70}
                  id="carousel-id-image-sponsor-4"
                  src="/assets/images/resource/image-4.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide id="carousel-id-sponsor-5" className="image">
                <Image
                  width={167}
                  height={70}
                  id="carousel-id-image-sponsor-5"
                  src="/assets/images/resource/image-5.png"
                  alt=""
                />
              </SwiperSlide>
              <SwiperSlide id="carousel-id-sponsor-6" className="image">
                <Image
                  width={122}
                  height={70}
                  id="carousel-id-image-sponsor-6"
                  src="/assets/images/resource/image-6.png"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </section>
      {/* section four */}
      {rooms && <RoomsSuites rooms={rooms} />}
      {/* section five */}
      <section className="section-five">
        <div className="auto-container">
          <div className="block-5">
            <div className="row">
              <div className="col-lg-6">
                <div className="block-five">
                  <div className="image">
                    <Image
                      width={142}
                      height={142}
                      src="/assets/images/resource/image-7.png"
                      alt=""
                    />
                  </div>
                  <div className="inner-box">
                    <div className="time">Time &amp; Shedule</div>
                    <h3>Opening Hours</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="block-five">
                  <div className="icon">
                    <i className="flaticon-tray" />
                    <span>Dinner: 4:30pm – 10:30pm</span>
                  </div>
                  <div className="icon">
                    <i className="flaticon-martini" />
                    <span>Bar (Drinks + Bites): 4:30pm – 12am</span>
                  </div>
                  <div className="icon">
                    <i className="flaticon-bath-tub" />{" "}
                    <span>Cocktails &amp; Whiskey: Until 2am</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section six */}
      <section className="section-six">
        <div className="auto-container">
          <div className="sub-title text-center">Features</div>
          <h2 className="sec-title text-center">Core Features</h2>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-wifi-signal" />
                </div>
                <h4>Free Wifi</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-breakfast" />
                </div>
                <h4>Breakfast</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-martini" />
                </div>
                <h4>Restaurant</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-car" />
                </div>
                <h4>Free Parking</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-terrace" />
                </div>
                <h4>Terrace</h4>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="block-six">
                <div className="icon">
                  <i className="flaticon-plane" />
                </div>
                <h4>Transport</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* section seven */}
      <section className="section-seven">
        <h4 className="d-none">heading</h4>
        <div className="auto-container">
          <div className="block-seven">
            <div className="image">
              <Image
                width={1139}
                height={632.766}
                src="/assets/images/resource/video.jpg"
                alt=""
              />
            </div>
            <div className="video-btn">
              <a
                href="https://www.youtube.com/watch?v=nfP5N9Yc72A&t=28s"
                className="overlay-link play-now ripple"
                data-fancybox="gallery"
                data-caption=""
              >
                <i className="fas fa-play" />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* testimonials section */}
      {isClient && <TestimonialsFeedbacks feedbacks={feedbacks} />}
      {/* section nine */}
      <section className="section-nine">
        <div className="auto-container">
          <div className="row"></div>
        </div>
      </section>
      {/* section ten */}
      <section className="section-ten">
        <div className="auto-container">
          <div
            className="block-eleven"
            style={{
              backgroundImage: "url(assets/images/resource/image-12.jpg)",
            }}
          >
            <div className="inner-box text-center">
              <div className="logo">
                <Image
                  width={80}
                  height={80}
                  src="/assets/images/resource/image-11.png"
                  alt=""
                />
              </div>
              <h5>Late Breakfast Until 10Am</h5>
              <Link href="/menu">
                <a className="theme-btn btn-style-one">
                  <span>Breakfast Menu</span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export async function getServerSideProps() {
  try {
    const rooms = await getTopRooms();
    const feedbacksInfo = await getTopFeedbacks();
    const feedbacks = feedbacksInfo?.data;
    return { props: { rooms, feedbacks } };
  } catch (error) {
    console.error(`An exception occurred: ${error.message}`);
    return { props: { rooms: [], feedbacks: [] } };
  }
}

export default Index;
