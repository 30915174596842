import React from "react";
import RoomSuite from "./room-suite.component";

const RoomsSuites = ({ rooms }) => {
  return (
    <section className="section-four">
      <div className="auto-container">
        <div className="sub-title text-center">Gallary</div>
        <h2 className="sec-title text-center">Rooms &amp; Suites</h2>
        <div className="row">
          {rooms?.map((room, index) => (
            <RoomSuite key={index} room={room} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RoomsSuites;
