import Image from "next/image";
import React from "react";

const FeedbackSlide = ({ feedback }) => {
  const stars = [1, 2, 3, 4, 5];
  const feedbackAttributes = feedback?.attributes;
  const profilePicture =
    feedback?.attributes?.guest?.data?.attributes?.picture?.data?.attributes
      ?.image?.data?.attributes?.url ||
    "/assets/images/resource/default-profile-picture.png";
  return (
    <>
      <div className="icon">
        <i className="flaticon-conference" />
      </div>
      <div className="inner-box">
        <div className="author-info">
          <div className="thumb">
            <Image src={profilePicture} alt="" width={70} height={70} />
          </div>
          <div className="rating-info">
            <div className="rating">
              {stars?.map((star, index) => {
                return star <= feedbackAttributes?.stars ? (
                  <Image
                    width={15}
                    height={15}
                    src={"/assets/images/icons/star-solid.svg"}
                    key={`star-full-user-${feedback?.id}-${index}`}
                    alt=""
                  />
                ) : (
                  <Image
                    width={15}
                    height={15}
                    src={"/assets/images/icons/star-regular.svg"}
                    key={`star-empty-user-${feedback?.id}-${index}`}
                    alt=""
                  />
                );
              })}
            </div>
          </div>
          <h4>{feedbackAttributes?.title}</h4>
        </div>
        <div className="content">
          <div className="text">{feedbackAttributes?.description}</div>
        </div>
      </div>
    </>
  );
};

export default FeedbackSlide;
