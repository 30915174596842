import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import Image from "next/image";
import { bannerSlider } from "../props/sliderProps";

const BannerSliderHomepage = () => {
  return (
    <Swiper {...bannerSlider} className="swiper-container banner-slider">
      <div className="swiper-wrapper">
        {/* Slide Item */}
        <SwiperSlide className="swiper-slide">
          <div className="content-outer">
            <div className="content-box">
              <div className="inner">
                <div className="logo">
                  <Image
                    src="/assets/images/icons/image-2.png"
                    alt=""
                    width={40.99}
                    height={40}
                  />
                </div>
                <h1 className="banner-title">
                  Experience
                  <br />
                  Pyramid <br />
                  Perfection <br />
                </h1>
                <div className="link-box">
                  <Link href="/room-list/1">
                    <a className="theme-btn btn-style-one">
                      <span>View Our Rooms</span>
                    </a>
                  </Link>
                </div>
              </div>
              <div className="image-box">
                <div className="image">
                  <Image
                    src="/assets/images/resource/carousel-pyramids-view-diamond-1.png"
                    alt=""
                    width={495}
                    height={565.99}
                  />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* Slide Item */}
        <SwiperSlide className="swiper-slide">
          <div className="content-outer">
            <div className="content-box">
              <div className="inner">
                <div className="logo">
                  <Image
                    src="/assets/images/icons/image-2.png"
                    alt=""
                    width={40.99}
                    height={40}
                  />
                </div>
                <h1 className="banner-title">
                  Where
                  <br />
                  Dreams <br />
                  Take Flight <br />
                </h1>
                <div className="link-box">
                  <Link href="/room-list/1">
                    <a className="theme-btn btn-style-one">
                      <span>View Our Rooms</span>
                    </a>
                  </Link>
                </div>
              </div>
              <div className="image-box">
                <div className="image">
                  <Image
                    src="/assets/images/resource/carousel-pyramids-view-diamond-2.png"
                    alt=""
                    width={495}
                    height={565.99}
                  />
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </div>
    </Swiper>
  );
};

export default BannerSliderHomepage;
