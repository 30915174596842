import React from "react";
import { testimonialSlider } from "../props/sliderProps";
import { Swiper, SwiperSlide } from "swiper/react";
import FeedbackSlide from "./feedback-slide.component";

const TestimonialsFeedbacks = ({ feedbacks }) => {
  return (
    <section className="testimonials-section">
      <div className="auto-container">
        <div className="top-content">
          <div className="sec-title text-center mb-30">
            <div className="sub-title">Testimonials</div>
            <h2 className="sec-title">User Feedbacks</h2>
          </div>
        </div>

        <div className="row">
          <Swiper
            {...testimonialSlider}
            className="theme_carousel swiper-container"
          >
            {feedbacks &&
              feedbacks?.map((feedback, index) => (
                <SwiperSlide
                  key={`swiper-Slide-${index}-${feedback?.id}`}
                  className="col-lg-12 block-eight text-center"
                >
                  <FeedbackSlide feedback={feedback} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsFeedbacks;
