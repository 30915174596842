import Image from "next/image";
import Link from "next/link";
import React from "react";

const RoomSuite = ({ room }) => {
  const imageUrl = room?.pictures[0]?.image?.url || "";

  return (
    <div className="col-lg-6">
      <Link href={`/room-details/${room?.id}`} passHref>
        <div className="block-four">
          <div className="image">
            {imageUrl && imageUrl != "" ? (
              <Image width={570} height={301} src={imageUrl} alt="" />
            ) : (
              <p>undefined</p>
            )}
          </div>
          <div className="inner-box">
            <div className="pricing">${room?.price} per day</div>
            <div className="text">{room?.name}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RoomSuite;
